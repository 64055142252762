import React, { useState, useEffect, useRef } from "react";
import Link from "../../components/Link";
import Media from "../../components/common/Media";
import { styled } from "../../stitches.config";
import CategoryBadge from "../CategoryBadge";
import Avatar from "../atoms/Avatar";
import PlayerIconBackground from "../assets/icons/player-icon.svg";
import IconMamadu from "../assets/icons/jakosc_mamaDu_etykieta.svg";
import HotNews from "../assets/icons/hot-news.svg";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CompositionRating from "./CompositionRating"

// embedy
import UniversalEmbedBox from "./UniversalEmbedBox";

interface IProps {
  article: any;
  media?: any;
  style?: string;
  withBackground?: boolean;
  dataAnalyticsName?: string;
  fetchpriority?: string;
}

const PhotoOnTop: React.FC<IProps> = ({
  media,
  article,
  style,
  withBackground,
  dataAnalyticsName,
  fetchpriority,
  ...props
}) => {
  const imgUrl = article?.picture?.img;
  const [height, setHeight] = useState(200);
  const pictureContainer = useRef<HTMLDivElement>(null);
  const partner = (article.partner ? <Partner>{article.partner}</Partner> : null);
  const iconTesting = (article.icon === "TESTUJEMY" ? <IconTesting></IconTesting> : null);
  const rating = (article.rating && !article.hideRating ? <CompositionRating rating={article.rating} /> : null);
  const showSkeleton = article.url ? false : true;
  const categoryBadge = (article?.category && article.category?.name ?
    <div className="category">
      <CategoryBadge
        text={article.category.name}
        showMark
        markText=""
        categoryColor={article.categoryColor}
      />
    </div>
    :
    null
  );

  console.log("test:", article)

  useEffect(() => {
    if (pictureContainer?.current?.clientWidth) {
      const eltWidth = pictureContainer?.current?.clientWidth;
      const cHeight = Math.ceil((eltWidth * 2) / 3);

      if (cHeight !== height && cHeight > 100) {
        setHeight(cHeight);
      }

      //console.log('cHeight', height, cHeight, media, pictureContainer);
    }
  }, []);

  const hasEmbed = article.embed && article.embed.type;

  return (
    <>
      {hasEmbed && article.embed.type != "iframe" ?
        <UniversalEmbedBox article={article} style={style} withBackground={withBackground} />
        :
        <Wrapper
          className={
            "photo-on-top style--" +
            style +
            (withBackground ? " style--with-padding" : "")
          }
        >
          <Link to={article.settings?.link || article.url} data-analytics-name={dataAnalyticsName}>
            <StyledPhotoOnTop ref={pictureContainer} size={article.size}>
              <StyledImage size={article.size}>
                <Media
                  media={media}
                  embed={article?.embed?.url}
                  src={imgUrl}
                  preserveRatio={false}
                  aspectRatio={1.5}
                  height={height}
                  fetchpriority={fetchpriority}
                />
                {/* {comments && <div className="comments"><Comments number={comments} /></div>} */}
                {rating}
                {partner}
                {categoryBadge}
                {iconTesting}
                {article?.iconHotNews && <IconHotNews />}
                {article?.player && <PlayerIcon boxSize="xxl" />}
              </StyledImage>
            </StyledPhotoOnTop>
            <StyledWrap size={article?.size}>

              <span className="title">
                {showSkeleton ? <Skeleton count={3} /> : article?.settings?.title_normal || article?.pile_title || article?.title}
              </span>

            </StyledWrap>
          </Link>

          {article.author?.show && article.author?.name && (
            <>
              <Link to={article.author?.url}>
                <WrapperAuthor className="photo-on-top__author">
                  <Avatar
                    src={article.author?.img}
                    url={article.author?.url}
                    size="extrasmall"
                  />
                  <p>{article.author?.name}</p>
                </WrapperAuthor>
              </Link>
              {/* <Author
            article={{
              author: {
                name: article?.settings?.author?.name,
                img: `${window.MEDIA_DOMAIN}/${article.settings.author?.img}`,
              },
            }}
          /> */}
            </>
          )}
        </Wrapper>
      }
    </>
  );
};

const Partner = styled("span", {
  display: 'block',
  position: 'absolute',
  left: '0',
  top: '0',
  padding: '0px 8px',
  backgroundColor: '#D8D8D8',
  color: '#8B8B8B',
  lineHeight: '16px',
  fontSize: '11px',
  zIndex: '4',
});

const IconTesting = styled("div", {
  position: 'absolute',
  right: '10px',
  top: '0px',
  width: "40px",
  height: "60px",
  backgroundImage: `url(${IconMamadu})`,
  backgroundSize: "cover",
  zIndex: '10',
});

const IconHotNews = styled("div", {
  position: 'absolute',
  right: '0px',
  bottom: '0px',
  width: "36px",
  height: "36px",
  backgroundImage: `url(${HotNews})`,
  backgroundSize: "16px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundColor: "$aszdziennikPrimary",
  zIndex: '3',
});
// poprawić problem 0 z komentarzami
const StyledPhotoOnTop = styled("div", {
  // width: "100%",
  variants: {
    size: {
      lg: {
        "@bp4": {
          width: "455px",
          height: "330px",
        },
        "@bp5": {
          width: "624px",
          height: "416px",
        },
      },
      md: {
        "@bp4": {
          width: "200px",
          // height: "100px",
        },
        "@bp5": {
          width: "408px",
          height: "272px",
        },
      },
    },
  },
  "& a": {
    textDecoration: "none !important",
  },
});

const StyledImage = styled("div", {
  position: "relative",
  "& img": {
    width: "100%",
    height: "auto",
    display: "block",
  },
  /*
  width: "328px",
  height: "220px",
  marginRight: "10px",
  position: "relative",
  margin: "0 auto",
  "@bp3": {
        width: "300px",
      height: "200px",
  },
      "@bp5": {
        margin: "0",
  },
      */
  variants: {
    size: {
      lg: {
        /*
        "@bp4": {
          width: "455px",
          height: "330px",
        },
        "@bp5": {
          width: "624px",
          height: "416px",
        },
        */
      },
      md: {
        /*
        "@bp5": {
          marginBtoon: "16px",
          width: "408px",
          height: "272px",
        },
        */
      },
    },
  },
  /*
  "& img": {
        width: "100%",
      // height: "auto !important"
      height: "100%",
  },
      */

  "& .category": {
    position: "absolute",
    bottom: "16px",
    left: "16px",

    zIndex: "3",
  },

  "& .comments": {
    position: "absolute",
    top: "16px",
    right: "16px",
    zIndex: "3",
  },
});
const StyledWrap = styled("div", {
  display: "block",
  color: "$grayscale100",
  // width: "328px",
  padding: "16px 0 0px",
  margin: "0 auto",
  "& .title": {
    fontFamily: "$grotesk",
    fontSize: 24,
    lineHeight: "30px",
    letterSpacing: "0.2px",
    fontWeight: "700",
  },
  "@bp4": {
    height: "111px",
    overflow: "hidden",

    "& .title": {
      fontSize: 18,
      lineHeight: "24px",
    },
  },
  "@bp5": {
    margin: "0",
    height: "87px",
    overflow: "hidden",
  },
  variants: {
    size: {
      lg: {
        // width: "455px",
        "@bp5": {
          width: "570px",
          margin: "0",
          // height: '400px',
        },
        p: {
          fontSize: "24px",
          lineHeight: "30px",
          "@bp4": {
            fontSize: "30px",
            lineHeight: "34px",
          },
          "@bp5": {
            fontSize: "38px",
            lineHeight: "42px",
          },
        },
      },
      md: {
        margin: "0",
        p: {
          fontSize: "24px",
          lineHeight: "30px",
        },
      },
    },
  },
});
const WrapperAuthor = styled("div", {
  display: "flex",
  alignItems: "center",
  marginTop: "18px",
  p: {
    marginTop: "0",
    marginBottom: "0",
    marginLeft: "12px",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "800",
    color: "$grayscale100",
  },
  /* ukrywanie główki autora w category mobile */
  "@mobile": {
    display: "none"
  }
});


const Wrapper = styled("div", {
  paddingBottom: "16px",
  marginBottom: "8px",
  width: "100%",
  "& a": {
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "$grayscale100 !important",
    },
  },

  "&.style--red": {
    [`${StyledWrap} > span`]: {
      backgroundColor: "#EA3A3E",
      color: "white",
      boxShadow: "2px 0 0 red, -2px 0 0 #EA3A3E",
    },

    "& a": {
      "&:hover": {
        textDecorationColor: "$grayscale0Fixed !important",
      },
    },
  },

  "&.style--primary": {
    [`${StyledWrap} > span`]: {
      backgroundColor: "$primary",
      color: "white",
      boxShadow: "2px 0 0 red, -2px 0 0 $primary",
    },
    "& a": {
      "&:hover": {
        textDecorationColor: "$grayscale0Fixed !important",
      },
    },
  },

  "&.style--black": {
    [`${StyledWrap} > span`]: {
      backgroundColor: "#131212",
      color: "white",
      boxShadow: "2px 0 0 #131212, -2px 0 0 #131212",
    },
    "& a": {
      "&:hover": {
        textDecorationColor: "$grayscale0Fixed !important",
      },
    },
  },

  "&.style--with-padding": {
    backgroundColor: "$grayscale0",
    paddingBottom: "1px",
    [`${StyledWrap}`]: {
      margin: "16px",
      padding: 0,
      "@bp4": {
        height: "70px",
        overflow: "hidden"
      },
    },
    [`${WrapperAuthor}`]: {
      margin: "15px 16px",
      padding: 0,
    }
  },
});

const PlayerIcon = styled("div", {
  position: "absolute",
  zIndex: 100,
  backgroundImage: `url(${PlayerIconBackground})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",

  top: "50%",
  left: "50%",
  width: "48px",
  height: "48px",
  marginLeft: "-24px",
  marginTop: "-24px",

  variants: {
    boxSize: {
      xxl: {
        backgroundSize: "48px",
      },
      xs: {
        backgroundSize: "32px",
      },
      xxs: {
        backgroundSize: "28px",
      },
    },
  },
});

export default PhotoOnTop;
