import React, { useCallback } from 'react';
import { styled } from "../../stitches.config"
import { pStrong } from "../commonStyles/Fonts";
import fullStarIcon from "../assets/icons/review-star.svg";
import emptyStarIcon from "../assets/icons/review-empty-star.svg";
import halfStarIcon from "../assets/icons/review-half-star.svg";
import { withThemeContext } from "../components/StitchesThemeProvider";
type RatingProps = {
    rating: number
}

const CompositionRating: React.FC<RatingProps> = ({
    rating,
}) => {
    const numberOfFullStars = Math.floor(rating);
    const numberOfEmptyStars = Math.floor(5 - rating);
    const numberOfHalfStars = 5 - numberOfFullStars - numberOfEmptyStars;

    const renderStars = useCallback(() => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= numberOfFullStars) {
                stars.push(<StyledFullStar key={`ratingStar${i}`} />);
            } else if (i <= (numberOfFullStars + numberOfHalfStars)) {
                stars.push(<StyledHalfStar key={`ratingStar${i}`} />);
            } else {
                stars.push(<StyledEmptyStar key={`ratingStar${i}`} />);
            }
        }
        return stars;
    }, [rating]);

    return (
        <>
            <StyledRating>
                <StyledStars>
                    {renderStars()}
                </StyledStars>
            </StyledRating>
        </>
    );
};

const StyledRating = styled('div', {
    backgroundColor: "$primary",
    display: "flex",
    position: 'absolute',
    left: '16px',
    bottom: '16px',
    height: "20px",
    width: "110px",
    zIndex: '4',
})

const StyledStars = styled('div', {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
})
const StyledStar = styled('div', {
    width: "16px",
    height: "16px",
    margin: "3px 2px 4px 2px",
    backgroundSize: "cover",
})
const StyledFullStar = styled(StyledStar, {
    backgroundImage: `url(${fullStarIcon})`,
})
const StyledHalfStar = styled(StyledStar, {
    backgroundImage: `url(${halfStarIcon})`,
})
const StyledEmptyStar = styled(StyledStar, {
    backgroundImage: `url(${emptyStarIcon})`,
})
export default withThemeContext(CompositionRating)