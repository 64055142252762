import React from "react";
import { styled } from "../../stitches.config";

import Heading from "../atoms/Heading";
import PhotoOnTop from "../components/PhotoOnTop";
import PhotoOnLeft from "../components/PhotoOnLeft";

import { SimilarArticlesType } from "../../stores/ArticleStore";

interface SimilarArticlesProps {
  articles: SimilarArticlesType[];
}

const SimilarArticles: React.FC<SimilarArticlesProps> = ({
  articles,
  ...props
}) => {
  const isDesktop = window.innerWidth > 996;
  return (
    <StyledSimilarArticles>
      <div className="container">
        {isDesktop ? (
          <Heading
            title={"Zobacz również"}
            specialHeading={true}
            specialHeadingStyle="specialDots"
          />
        ) : (
          <CustomHeader>Zobacz również</CustomHeader>
        )}
        <div className="row">
          <Wrapper>
            {articles.map((article, i) => (
              <div className="col-lg-3 col-sm-12">
                {isDesktop ? (
                  <PhotoOnTop key={"similar_articles__" + i} article={article} dataAnalyticsName={"article-polecane-art-desktop-" + i} />
                ) : (
                  <MarginWrapper>
                    <PhotoOnLeft
                      key={"similar_articles__" + i}
                      article={article}
                      boxSize="xs"
                      dataAnalyticsName={"article-polecane-art-mobile-" + i}
                    />
                  </MarginWrapper>
                )}
              </div>
            ))}
          </Wrapper>
        </div>
      </div>
    </StyledSimilarArticles>
  );
};

const StyledSimilarArticles = styled("div", {
  padding: "32px 0 40px 0",
  "@bp4": {
    padding: "64px 0 32px 0",
  },
});

const Wrapper = styled("div", {
  "@bp4": {
    marginTop: "48px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-beetwen",
  },
  "& .photo-on-top": {
    marginBottom: "24px",
    "@bp4": {
      marginBottom: "56px",
    },
  },
});

const Grid = styled("div", {
  width: "100%",
  "@bp4": {
    width: "300px",
  },
});

const MarginWrapper = styled("div", {
  marginTop: "24px",
});

const CustomHeader = styled("div", {
  width: "100%",
  textAlign: "center",
  fontSize: "24px",
  lineHeight: "30px",
  fontWeight: "700",
  color: "$grayscale100",
});

export default SimilarArticles;
